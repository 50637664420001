import {
	Box,
	Button,
	Container,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Alert,
} from "@mui/material";
import WifiProtectedSetupIcon from '@mui/icons-material/WifiProtectedSetup';
import CancelIcon from '@mui/icons-material/Cancel';
import swal from "sweetalert";
import axios from "axios";
import { DashboardLayout } from "../../components/dashboard-layout";
import { ThemeProvider } from "@mui/material/styles";
import "./account.css";
import { theme } from "../../theme";
import { useParams } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { GetNameChoose } from "../../funcUtils";

function UserProfile() {
	const { id } = useParams();
	const [isShow, setShow] = useState(false);
	const [data, setData] = useState(null);
	const [load, setLoad] = useState(false);
	const [profile, setProfile] = useState(null);
	const [bank, setBank] = useState(null);
	const [history, setHistory] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [itemSelected, setItemSelected] = useState(null);
	const refSelectBet = useRef(null);

	function formatDate(m) {
		new Date(m);
		const dateString =
			m.getUTCFullYear() +
			"/" +
			("0" + (m.getMonth() + 1)).slice(-2) +
			"/" +
			("0" + m.getDate()).slice(-2) +
			"  " +
			("0" + m.getHours()).slice(-2) +
			":" +
			("0" + m.getMinutes()).slice(-2);
		return dateString;
	}
	axios.interceptors.request.use(
		(config) => {
			const token = localStorage.getItem("user");

			if (token) {
				config.headers["Authorization"] = `Bearer ${token}`;
			}

			return config;
		},

		(error) => {
			return Promise.reject(error);
		}
	);
	useEffect(() => {
		if (id && load == false) {
			axios
				.post(`${process.env.REACT_APP_API_URL}/bank/getBankUser`, { id: id })
				.then((res) => {
					setData(res.data.data);
					setLoad(true);
				})
				.catch((res) => setData(null));
		}
		axios
			.get(`${process.env.REACT_APP_API_URL}/auth/user/${id}`, {})
			.then((res) => {
				setProfile(res.data.data);
			})
			.catch((res) => setProfile(null));
		axios
			.get(`${process.env.REACT_APP_API_URL}/history/historyuser/${id}`, {})
			.then((res) => {
				setHistory(res.data.data);
			})
			.catch((res) => setHistory(null));
	}, [load]);
	const handleSubmit = (e) => {
		e.preventDefault();
		axios
			.post(`${process.env.REACT_APP_API_URL}/auth/changepassword`, {
				id: id,
				password: e.target.password.value,
			})
			.then((res) => {
				setLoad(false);
				swal("Cập nhật thành công");
			})
			.catch((err) => {
				swal("Có lỗi vui lòng thử lại!");
				setData(null);
			});
	};
	// const handleSubmitBank = (e) => {
	// 	e.preventDefault();
	// 	const formData = {
	// 		id: bank._id,
	// 		name_bank: e.target.name_bank.value,
	// 		stk: e.target.stk.value,
	// 		fullname: e.target.fullname.value,
	// 	};
	// 	axios
	// 		.post(`${process.env.REACT_APP_API_URL}/bank/updateBank`, formData)
	// 		.then((res) => {
	// 			setShow(false);
	// 			swal("Cập nhật thành công");
	// 			setLoad(false);
	// 		})
	// 		.catch((err) => {
	// 			setShow(false);
	// 			swal("Có lỗi vui lòng thử lại!");
	// 			setLoad(false);
	// 		});
	// };

	const handleSelect = (e) => {
		setShowModal(true);
		setItemSelected(e);
	}
	const handleSubmitEdit = () => {
		let selected = refSelectBet.current.selectedIndex;
		if(selected > 0){
			const _id = history[itemSelected]._id;
			const _idbet = history[itemSelected]._idbet;
			setLoad(true);
			axios
			.post(`${process.env.REACT_APP_API_URL}/auth/history/xucxac/editbet`, {
				id: _id,
				idbet: _idbet,
				state: selected.toString(),
				iduser: profile._id,
			})
			.then((res) => {
				setLoad(false);
				swal("Cập nhật thành công");
				setShowModal(false);
			})
			.catch((err) => {
				setLoad(false);
				swal("Có lỗi vui lòng thử lại!");
			});

		}else{
			setShowModal(false);
		}


	}

	const handleForceLose = (_id, _money) => {
		const confirm = window.confirm('Xác nhận ép phiên cược thành thua (không thể hoàn tác)')
		if(confirm){
			setLoad(true);
			axios
			.post(`${process.env.REACT_APP_API_URL}/auth/history/xucxac/forcelose`, {
				id: _id,
				iduser: profile._id,
				money: Number(_money),
			})
			.then((res) => {
				setLoad(false);
				swal("Cập nhật thành công");
				setShowModal(false);
			})
			.catch((err) => {
				setLoad(false);
				swal("Có lỗi vui lòng thử lại!");
			});

		}
	}

	return (
		<>
			<ThemeProvider theme={theme}>
				<DashboardLayout>
					{
						<Box
							component="main"
							sx={{
								flexGrow: 1,
								py: 8,
							}}
						>
							<Container maxWidth={false}>
								<div style={{ fontSize: "25px", fontWeight: 700 }}>
									Chỉnh sửa thông tin User
								</div>
								<div className="item_accountprofile">
									<div className="account_profile">
										<div className="titleitem">Thông tin User</div>
										{profile != null ? (
											<>
												<div className="avb_">
													<div className="detail_user">
														<div className="username_">Tên đăng nhập</div>
														<div className="username_" style={{ color: 'orange' }}>{profile.username}</div>
													</div>
													<div className="detail_user">
														<div className="username_">SĐT</div>
														<div className="username_">
															{profile.sdt ? "" + profile.sdt : "Chưa có sđt"}
														</div>
													</div>
													<form onSubmit={handleSubmit}>
														<div className="detail_user">
															<div className="username_">Mật khẩu</div>
															<TextField name="password" />
															<Button type="submit">Xác nhận</Button>
														</div>
													</form>
													<div className="detail_user">
														<div className="username_">Mã giới thiệu</div>
														<div className="username_">
															{profile.code}
														</div>
													</div>
													<div className="detail_user">
														<div className="username_">Ngày lập nick</div>
														<div className="username_">
															{formatDate(new Date(profile.createdAt))}
														</div>
													</div>
												</div>
											</>
										) : null}
									</div>
									<div className="edit_account_bankl">
										<div className="titleitem">Thông tin tài chính</div>

										{profile != null ? (
											<>
												<div className="avb_" style={{ minWidth: '280px' }}>
													<div className="detail_user">
														<div className="username_">Tiền user</div>
														<div className="username_">
															{Math.floor(profile.money).toLocaleString()}
														</div>
													</div>
													<div className="detail_user">
														<div className="username_">Tiền đã cược</div>
														<div className="username_">
															{Number(profile.totalbet).toLocaleString()}
														</div>
													</div>
													<div className="detail_user">
														<div className="username_">Tiền đã win</div>
														<div className="username_">
															{Number(profile.totalwin).toLocaleString()}
														</div>
													</div>
												</div>
											</>
										) : null}
										{/* <Table sx={{ width: 700 }}>
											<TableHead>
												<TableRow>
													<TableCell>Tên Ngân Hàng</TableCell>
													<TableCell>STK</TableCell>
													<TableCell>Người Nhận</TableCell>
													<TableCell>Hành động</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{data != null ? (
													<>
														{data.map((item) => (
															<TableRow>
																<TableCell sx={{ fontWeight: "600" }}>
																	{item.name_bank}
																</TableCell>
																<TableCell sx={{ fontWeight: "600" }}>
																	{" "}
																	{item?.stk}
																</TableCell>
																<TableCell sx={{ fontWeight: "600" }}>
																	{item.fullname}
																</TableCell>
																<TableCell sx={{ fontWeight: "600" }}>
																	<Button
																		onClick={() => {
																			axios
																				.delete(
																					`${process.env.REACT_APP_API_URL}/bank/delete/${item._id}`
																				)
																				.then((res) => {
																					setLoad(false);
																				});
																		}}
																	>
																		Xóa
																	</Button>
																	<Button
																		onClick={() => {
																			axios
																				.get(
																					`${process.env.REACT_APP_API_URL}/bank/user/${item._id}`,
																					{}
																				)
																				.then((res) => {
																					setBank(res.data.data);
																					setShow(true);
																				})
																				.catch((res) => {
																					swal(
																						"Lấy thông tin không thành công"
																					);
																					setLoad(false);
																				});
																		}}
																	>
																		Sửa
																	</Button>
																</TableCell>
															</TableRow>
														))}
													</>
												) : (
													<div
														style={{
															fontSize: "16px",
															textAlign: "center",
															padding: "10px",
														}}
													>
														Đang cập nhật dữ liệu
													</div>
												)}
											</TableBody>
										</Table> */}
									</div>
								</div>
								<Table sx={{ width: "100%" }}>
									<TableHead>
										<TableRow>
											<TableCell>Phiên số</TableCell>
											<TableCell>Game</TableCell>
											<TableCell>Chọn</TableCell>
											<TableCell>Kết quả</TableCell>
											<TableCell>Coin</TableCell>
											<TableCell>Coin nhận</TableCell>
											<TableCell>Thời gian</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{history != null ? (
											<>
												{history.map((item, index) => (
													<TableRow key={index}>
														<TableCell className="notranslate" sx={{ fontWeight: "600" }}>
															{item._idbet}
														</TableCell>
														<TableCell sx={{ fontWeight: "600" }}>
															{" "}
															{item.game}
														</TableCell>
														<TableCell sx={{ fontWeight: "600" }}>
															{GetNameChoose(item.state, undefined, item.game)}
															{item.game.includes('Xúc sắc') &&
																<IconButton
																	color='success'
																	size='small'
																	onClick={() => { handleSelect(index); }}
																>
																	<WifiProtectedSetupIcon />
																</IconButton>
															}
														</TableCell>
														<TableCell sx={{ fontWeight: "600" }}>
															{item.status_bet}
															{(item.game.includes('Xúc sắc') && item.status_bet == 'Win') &&
																<IconButton
																	color='error'
																	size='small'
																	onClick={() => { handleForceLose(item._id, item.money_recv); }}
																>
																	<CancelIcon fontSize="inherit" />
																</IconButton>
															}
														</TableCell>
														<TableCell sx={{ fontWeight: "600" }}>
															{item.money.toLocaleString()}
														</TableCell>
														<TableCell sx={{ fontWeight: "600" }}>
															{Math.round(Number(item.money_recv)).toLocaleString()}
														</TableCell>
														<TableCell sx={{ fontWeight: "600" }} style={{ textAlign: 'left' }}>
															{formatDate(new Date(item.time))}
														</TableCell>
													</TableRow>
												))}
											</>
										) : (
											<div
												style={{
													fontSize: "16px",
													textAlign: "center",
													padding: "10px",
												}}
											>
												Đang cập nhật dữ liệu
											</div>
										)}
									</TableBody>
								</Table>
							</Container>
						</Box>
					}
					{showModal &&
						<div className="modal" id='modal_edit_withdraw_info'>
							<div className="modaloverlay">
								<i className="ti-close closelogin"></i>
							</div>
							<div
								style={{ backgroundColor: "white" }}
								className="modalbody"
							>
								<div className="modalinner" style={{ marginTop: '0px' }}>
									<div className="modalheader" style={{ paddingTop: '0px' }}>Sửa lịch sử cược</div>
									<Alert severity='info'>
										<div style={{ textAlign: 'left' }}>
											<p>User: <b>{profile?.username}</b></p>
											<p>Game: <b>{history[itemSelected]?.game}</b></p>
											<p>Phiên số: <b>{history[itemSelected]?._idbet}</b></p>
											<p>Cược: <b>{GetNameChoose(history[itemSelected]?.state, undefined, history[itemSelected]?.game)}</b></p>
											<p>Kết quả: <b>{history[itemSelected]?.status_bet}</b></p>
										</div>
									</Alert>
									<br />

									<Alert severity="success">
										<div style={{ textAlign: 'left' }}>
											<p>Chọn giá trị cược mới:</p>
											<select ref={refSelectBet} style={{minWidth: '120px'}}>
												<option value="0" hidden selected>---</option>
												<option value="1">Tài</option>
												<option value="2">Xỉu</option>
												<option value="3">Lẻ</option>
												<option value="4">Chẵn</option>
											</select>
										</div>
									</Alert>

									<div className="item_btn_form" style={{ marginBottom: 0, }}>
										<div className="modalformcontrols">
											<Button type="button" onClick={handleSubmitEdit}>XÁC NHẬN</Button>
										</div>
										<div className="modalformcontrols">
											<Button onClick={() => {
												setShowModal(false);
											}
											}>
												ĐÓNG
											</Button>
										</div>
									</div>
								</div>
							</div>
						</div>
					}

					{/* {isShow === true ? (
						<>
							<div className="modal">
								<div className="modaloverlay">
									<i className="ti-close closelogin"></i>
								</div>
								<div className="modalbody">
									{bank != null ? (
										<>
											<form onSubmit={handleSubmitBank}>
												<div className="modalinner">
													<div className="modalheader"> Sửa thông tin </div>

													<div className="modalform">
														<div
															className="modalformgroup"
															style={{ padding: "9px" }}
														>
															<TextField
																name="name_bank"
																defaultValue={bank.name_bank}
																sx={{ width: "100%" }}
																type="text"
																placeholder="Tên ngân hàng"
															/>
														</div>
														<div
															style={{ padding: "9px" }}
															className="modalformgroup"
														>
															<TextField
																name="stk"
																defaultValue={bank.stk}
																sx={{ width: "100%" }}
																type="number"
																placeholder="STK"
															/>
														</div>
														<div
															style={{ padding: "9px" }}
															className="modalformgroup"
														>
															<TextField
																name="fullname"
																defaultValue={bank.fullname}
																sx={{ width: "100%" }}
																type="text"
																placeholder="Tên tài khoản"
															/>
														</div>
													</div>

													<div className="item_btn_form">
														<div className="modalformcontrols">
															<Button type="submit">XÁC NHẬN</Button>
														</div>
														<div className="modalformcontrols">
															<Button onClick={() => setShow(false)}>
																ĐÓNG
															</Button>
														</div>
													</div>
												</div>
											</form>
										</>
									) : (
										<div>Đang chờ dữ liệu</div>
									)}
								</div>
							</div>
						</>
					) : null} */}
				</DashboardLayout>
			</ThemeProvider>
		</>
	);
}

export default UserProfile;
