import {
    Box,
    Container,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    TextField,
} from "@mui/material";
import swal from "sweetalert";
import axios from "axios";
import { DashboardLayout } from "../../components/dashboard-layout";
import { ThemeProvider } from "@mui/material/styles";
import "./account.css";
import { theme } from "../../theme";
import { useEffect, useState } from "react";

function InviteCode() {
    const [bank, setBank] = useState();
    const [load, setLoad] = useState(true);
    // const [isShow, setShow] = useState(false);
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");

            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }

            return config;
        },

        (error) => {
            return Promise.reject(error);
        }
    );
    // const [bankitem, setbankitem] = useState();
    useEffect(() => {
        if (load == true) {
            axios.get(`${process.env.REACT_APP_API_URL}/auth/invitecode`, {}).then((res) => {
                setBank(res.data.data);
                setLoad(false);
            });
        }
    }, [load]);
    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = {
            code: e.target.code.value.trim(),
            amount: e.target.amount.value,
        };

        axios
            .post(`${process.env.REACT_APP_API_URL}/auth/invitecode/add`, formData)
            .then((res) => {
                swal("Tạo mã giới thiệu thành công!").then((value) => {
                    window.location.reload();
                });
            })
            .catch((res) => {
                swal(res.response?.data?.message ? res.response.data.message : "Error!")
            });
    };
    // const handleSubmitBank = (e) => {
    //     e.preventDefault();
    //     const formData = {
    //         id: bankitem._id,
    //         fullname: e.target.fullname.value,
    //         stk: e.target.stk.value,
    //         name_bank: e.target.name_bank.value,
    //         title: e.target.title.value,
    //     };

    //     axios
    //         .post(`${process.env.REACT_APP_API_URL}/auth/editlistbank`, formData)
    //         .then((res) => {
    //             swal("Cập nhật thành công", "", "success");
    //             setLoad(true);
    //             setShow(false);
    //         });
    // };

    return (
        <>
            <ThemeProvider theme={theme}>
                <DashboardLayout>
                    {
                        <Box
                            component="main"
                            sx={{
                                flexGrow: 1,
                                py: 8,
                            }}
                        >
                            <Container maxWidth={false}>
                                <div style={{ fontSize: "25px", fontWeight: 700 }}>
                                    Quản lý mã giới thiệu
                                </div>
                                <div style={{ marginTop: "20px" }}>
                                    <form onSubmit={handleSubmit} className="form-admin">
                                        <div>
                                            <div>
                                                <label>Mã giới thiệu</label>
                                                <input
                                                    required
                                                    name="code"
                                                    step="any"
                                                    id="code"
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <label>Giới hạn</label>
                                                <input
                                                    type="number"
                                                    name="amount"
                                                    min={1}
                                                    step="1"
                                                    id="amount"
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div>
                                            <button className="btn_setting">Thêm mới</button>
                                        </div>
                                    </form>
                                </div><br />
                                <Table sx={{ width: "100%" }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Mã giới thiệu</TableCell>
                                            <TableCell>Đã đăng ký</TableCell>
                                            <TableCell>Giới hạn</TableCell>
                                            <TableCell style={{ textAlign: "right" }}>
                                                Hành động
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {bank != null ? (
                                            <>
                                                {bank.map((item) => (
                                                    <TableRow>
                                                        <TableCell className="notranslate" sx={{ fontWeight: "600" }}>
                                                            {item.code}
                                                        </TableCell>
                                                        <TableCell className="notranslate" sx={{ fontWeight: "600" }}>
                                                            {item.used} {item.used >= item.amount && '(MAX)'}
                                                        </TableCell>
                                                        <TableCell className="notranslate" sx={{ fontWeight: "600" }}>
                                                            {item.amount}
                                                        </TableCell>
                                                        <TableCell sx={{ fontWeight: "600" }}>
                                                            <Button
                                                                onClick={() => {
                                                                    if (window.confirm("Xác nhận xóa mã giới thiệu: " + item.code)) {
                                                                        axios
                                                                            .post(
                                                                                `${process.env.REACT_APP_API_URL}/auth/invitecode/delete`,
                                                                                { id: item._id }
                                                                            )
                                                                            .then((res) => {
                                                                                setLoad(true);
                                                                            })
                                                                        // .catch((res) => {
                                                                        // 	swal("Lấy thông tin không thành công");
                                                                        // 	setLoad(false);
                                                                        // });
                                                                    }
                                                                }}
                                                            >
                                                                Xóa
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </>
                                        ) : (
                                            <div
                                                style={{
                                                    fontSize: "16px",
                                                    textAlign: "center",
                                                    padding: "10px",
                                                }}
                                            >
                                                Đang cập nhật dữ liệu
                                            </div>
                                        )}
                                    </TableBody>
                                </Table>
                                {/* {isShow === true ? (
									<>
										<div className="modal">
											<div className="modaloverlay">
												<i className="ti-close closelogin"></i>
											</div>
											<div
												style={{ backgroundColor: "white" }}
												className="modalbody"
											>
												{bankitem != null ? (
													<>
														<form onSubmit={handleSubmitBank}>
															<div className="modalinner">
																<div className="modalheader">
																	{" "}
																	Sửa thông tin{" "}
																</div>
																<div className="modalform">
																	<div
																		className="modalformgroup"
																		style={{ padding: "9px" }}
																	>
																		<TextField
																			name="name_bank"
																			defaultValue={bankitem.name_bank}
																			sx={{ width: "100%" }}
																			type="text"
																			required
																			placeholder="Tên ngân hàng"
																		/>
																	</div>
																	<div
																		style={{ padding: "9px" }}
																		className="modalformgroup"
																	>
																		<TextField
																			name="stk"
																			defaultValue={bankitem?.stk}
																			sx={{ width: "100%" }}
																			type="number"
																			required
																			placeholder="STK"
																		/>
																	</div>
																	<div
																		style={{ padding: "9px" }}
																		className="modalformgroup"
																	>
																		<TextField
																			name="fullname"
																			defaultValue={bankitem.fullname}
																			sx={{ width: "100%" }}
																			type="text"
																			required
																			placeholder="Tên tài khoản"
																		/>
																	</div>
																	<div
																		style={{ padding: "9px" }}
																		className="modalformgroup"
																	>
																		<TextField
																			name="title"
																			defaultValue={bankitem.title}
																			sx={{ width: "100%" }}
																			type="text"
																			placeholder="Nội dung"
																		/>
																	</div>
																</div>
																<div
																	style={{ padding: "9px" }}
																	className="modalformgroup"
																></div>
																<div className="item_btn_form">
																	<div className="modalformcontrols">
																		<Button type="submit">XÁC NHẬN</Button>
																	</div>
																	<div className="modalformcontrols">
																		<Button onClick={() => setShow(false)}>
																			ĐÓNG
																		</Button>
																	</div>
																</div>
															</div>
														</form>
													</>
												) : (
													<div>Đang chờ dữ liệu</div>
												)}
											</div>
										</div>
									</>
								) : null} */}
                            </Container>
                        </Box>
                    }
                </DashboardLayout>
            </ThemeProvider>
        </>
    );
}

export default InviteCode;
