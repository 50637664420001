import {
    Avatar,
    Box,
    Card,
    CardContent,
    Grid,
    Typography
} from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import MoneyIcon from '@mui/icons-material/Money';

export const Budget = (props) => ( <
    Card sx = {
        {
            height: '100%',
            backgroundColor: '#3ba1c5'
        }
    } { ...props
    } >
    <
    CardContent >
    <
    Grid container spacing = {
        6
    }
    sx = {
        {
            justifyContent: 'space-between'
        }
    } >
    <
    Grid item >
    <
    Typography color = "textSecondary"
    gutterBottom variant = "overline" >
    Tổng nạp <
    /Typography> <
    Typography color = "textPrimary"
    variant = "h4" >
    {
        Number(props.tongnap).toLocaleString()
    }
    đ <
    /Typography> <
    /Grid> <
    Grid item >
    <
    Avatar sx = {
        {
            backgroundColor: 'error.main',
            height: 56,
            width: 56
        }
    } >
    <
    MoneyIcon / >
    <
    /Avatar> <
    /Grid> <
    /Grid> <
    /CardContent> <
    /Card>
);